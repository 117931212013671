* { box-sizing: border-box; }
@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');


body {
  font-family: 'Rubik', sans-serif;
}

.container {
  display: flex;
  justify-content: center;
  height: 90vh;
  padding: 2rem;
  width: 80%;
}
@media (max-width: 699px) {
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-left: -8rem !important;
  }
  .left {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    animation-name: left;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-delay: 1s;
    width: 100% !important;
  }
  .wrapper {
    width: 100% !important;
    margin-left: 7rem !important;
    margin-top: 2rem;
    padding: 0 2rem;
    text-align: center;
    animation: myAnim 20s ease 0s infinite normal forwards;
  }
  .polaroid {
    background: rgb(239, 65, 65);
    padding: 1rem;
    box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
    
  }
  .polaroid > img{
    width: 100% !important;
    height: auto;
  }
  .caption {
    font-size: 0.7rem !important;
    text-align: center;
    line-height: 2em;
    text-transform: uppercase;
    font-style: italic;
  }
  .item {
    width: 90% !important;
    display: inline-block;
    filter: grayscale(100%);
  }
  .formu > input{
    width: 120% !important;
  }
  .formu > textarea {
    width: 120% !important;
  }
  .header > h2 {
    width: 100%;
    color: #e32415 !important;
  }
}
@media (max-width: 1360px) {
  .container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    margin-left: -6rem !important;
  }
  .wrapper {
    width: 70% !important;
    margin-left: 7rem !important;
    margin-top: 2rem;
    padding: 0 2rem;
    text-align: center;
    animation: myAnim 20s ease 0s infinite normal forwards;
  }
  .polaroid {
    background: rgb(239, 65, 65);
    padding: 1rem;
    box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
    
  }
  .polaroid > img{
    width: 100% !important;
    height: auto;
  }
  .caption {
    font-size: 1rem !important;
    text-align: center;
    line-height: 2em;
    text-transform: uppercase;
    font-style: italic;
  }
  .item {
    width: 70% !important;
    display: inline-block;
    filter: grayscale(100%);
  }
  .formu > input{
    width: 150% !important;
  }
  .formu > textarea {
    width: 150% !important;
  }
  .header > h2 {
    width: 100%;
    color: #e32415 !important;
  }
  .formu > button {
    padding: 12px 10px;
    border: 0;
    background: linear-gradient(315deg, #ffbc00, #ff0058);
    border-radius: 3px;
    margin-top: 10px;
    width: 150% !important;
    color: #fff;
    letter-spacing: 1px;
    font-family: 'Rubik', sans-serif;
    cursor: pointer;
  }
}
@media (max-width: 1000px) {
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-left: -6rem !important;
  }
  .wrapper {
    width: 70% !important;
    margin-left: 7rem !important;
    margin-top: 2rem;
    padding: 0 2rem;
    text-align: center;
    animation: myAnim 20s ease 0s infinite normal forwards;
  }
  .polaroid {
    background: rgb(239, 65, 65);
    padding: 1rem;
    box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
    
  }
  .polaroid > img{
    width: 100% !important;
    height: auto;
  }
  .caption {
    font-size: 1rem !important;
    text-align: center;
    line-height: 2em;
    text-transform: uppercase;
    font-style: italic;
  }
  .item {
    width: 140% !important;
    display: inline-block;
    filter: grayscale(100%);
  }
  .formu > input{
    width: 150% !important;
  }
  .formu > textarea {
    width: 150% !important;
  }
  .header > h2 {
    width: 100%;
    color: #e32415 !important;
  }
  .formu > button {
    padding: 12px 10px;
    border: 0;
    background: linear-gradient(315deg, #ffbc00, #ff0058);
    border-radius: 3px;
    margin-top: 10px;
    width: 150% !important;
    color: #fff;
    letter-spacing: 1px;
    font-family: 'Rubik', sans-serif;
    cursor: pointer;
  }
}
@media (min-width: 1000px) and (max-width: 1500px) {
  .container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    margin-left: -6rem !important;
  }
  .wrapper {
    width: 30% !important;
    margin-left: 7rem !important;
    margin-top: 2rem;
    padding: 0 2rem;
    text-align: center;
    animation: myAnim 20s ease 0s infinite normal forwards;
  }
}


.left {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  animation-name: left;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-delay: 1s;
  margin-left: 6rem;
  width: 70%;
}

@keyframes myAnim {
	0%,
	100% {
		transform: translateY(0);
	}

	10%,
	30%,
	50%,
	70% {
		transform: translateY(-8px);
	}

	20%,
	40%,
	60% {
		transform: translateY(8px);
	}

	80% {
		transform: translateY(6.4px);
	}

	90% {
		transform: translateY(-6.4px);
	}
}
@media (max-width: 320px) {
  .formu {
    max-width: 130% !important;
    display: flex;
    flex-direction: column;
  }
  .formu > input{
    width: 200%;
  }
}
@media (max-width: 376px) {
  .formu {
    max-width: 130% !important;
    display: flex;
    flex-direction: column;
  }
  .formu > input{
    width: 200%;
  }
}
@media (max-width: 1026px) {
  .formu {
    max-width: 110% !important;
    display: flex;
    flex-direction: column;
  }
  .formu > input{
    width: 140% !important;
  }
  .formu > textarea {
    width: 140% !important;
  }
}

.wrapper {
  width: 100%;
  padding: 0 2rem;
  text-align: center;
  animation: myAnim 20s ease 0s infinite normal forwards;
}
.polaroid {
  background: rgb(255, 255, 255);
  padding: 1rem;
  box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
  
}
.polaroid > img{
  width: 100%;
  height: auto;
}
.caption {
  font-size: 1.3rem;
  text-align: center;
  line-height: 2em;
  text-transform: uppercase;
  font-style: italic;
}
.item {
  width: 70%;
  display: inline-block;
  filter: grayscale(100%);
}
.item .polaroid:before {
  content: '';
  position: absolute;
  z-index: -1;
  transition: all 0.35s;
}
.item:nth-of-type(4n+1) {
  transform: scale(0.8, 0.8) rotate(5deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+1) .polaroid:before {
  transform: rotate(6deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  right: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.4);
}
.item:nth-of-type(4n+2) {
  transform: scale(0.8, 0.8) rotate(-5deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+2) .polaroid:before {
  transform: rotate(-6deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  left: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.4);
}
.item:nth-of-type(4n+4) {
  transform: scale(0.8, 0.8) rotate(3deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+4) .polaroid:before {
  transform: rotate(4deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  right: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.3);
}
.item:nth-of-type(4n+3) {
  transform: scale(0.8, 0.8) rotate(-3deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+3) .polaroid:before {
  transform: rotate(-4deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  left: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.3);
}
.item:hover {
  filter: sepia(100%);
  transform: scale(1, 1) rotate(0deg) !important;
  transition: all 0.35s;
}
.item:hover .polaroid:before {
  content: '';
  position: absolute;
  z-index: -1;
  transform: rotate(0deg);
  height: 90%;
  width: 90%;
  bottom: 0%;
  right: 5%;
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.2);
  transition: all 0.35s;
}
form {
  width: 100%;
}


.header > h2 {
  margin: 0;
  color: #e32415;
}

.header > h4 {
  margin-top: 10px;
  font-weight: normal;
  font-size: 15px;
  color: rgba(255, 252, 252, 0.27);
}

.formu {
  max-width: 80%;
  display: flex;
  flex-direction: column;
}

.formu > p {
  text-align: right;
}

.formu > p > a {
  color: #000;
  font-size: 14px;
}
.formu > input{
  width: 180%;
}
.formu > textarea {
  width: 180%;
}
.form-field {
  height: 46px;
  padding: 0 16px;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-family: 'Rubik', sans-serif;
  outline: 0;
  transition: .2s;
  margin-top: 20px;
}

.formu-field:focus {
  border-color: #0f7ef1;
}

.formu > button {
  padding: 12px 10px;
  border: 0;
  background: linear-gradient(315deg, #ffbc00, #ff0058);
  border-radius: 3px;
  margin-top: 10px;
  width: 180%;
  color: #fff;
  letter-spacing: 1px;
  font-family: 'Rubik', sans-serif;
  cursor: pointer;
}

.button4:hover {
  background: linear-gradient(315deg, #4dff03, #00d0ff);
  color: black;
} 

.animation {
  animation-name: move;
  animation-duration: .4s;
  animation-fill-mode: both;
  animation-delay: 2s;
  width: 150%;
}

.a1 {
  animation-delay: 2s;
}

.a2 {
  animation-delay: 2.1s;
  color: #000000 !important;
}

.a3 {
  animation-delay: 2.2s;
}

.a4 {
  animation-delay: 2.3s;
}

.a5 {
  animation-delay: 2.4s;
}

.a6 {
  animation-delay: 2.5s;
}

@keyframes move {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

@keyframes left {
  0% {
    opacity: 0;
    width: 0;
  }

  100% {
    opacity: 1;
    padding: 20px 40px;
    width: 440px;
  }
}