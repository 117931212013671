.body-clientes {
	align-items: center;
	background: #ffffff;
	display: flex;
	height: 30vh;
	justify-content: center;
	margin-bottom: 2.5rem;
}
@media (max-width: 320px) {
	.body-clientes {
		align-items: center;
		background: #ffffff;
		display: flex;
		height: 30vh;
		justify-content: center;
		margin-bottom: 5rem;
	}
}
@media (max-width: 376px) {
	.body-clientes {
		margin-bottom: 5rem;
	}
}
@media (max-width: 426px) {
	.body-clientes {
		margin-bottom: 5rem;
	}
}
@media (max-width: 769px) {
	.body-clientes {
		margin-bottom: 5rem;
	}
}
@media (max-width: 1025px) {
	.body-clientes {
		margin-bottom: 5rem;
	}
}
@media (max-width: 1441px) {
	.body-clientes {
		margin-bottom: 5rem;
	}
}
@media (max-width: 2560px) {
	.body-clientes {
		margin-bottom: 5rem;
	}
}

@mixin white-gradient {
	background: linear-gradient(to right,  #ffffff 0%,rgba(255,255,255,0) 100%);
}

$animationSpeed: 40s;

// Animation

@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}


// Styling

.slider {
	background: #ffffff;
	height: 250px;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: 100%;
	
	&::before,
	&::after {
		@include white-gradient;
		content: "";
		height: 100px;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}
	
	.slide-track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		width: calc(250px * 14);
	}
	
	.slide {
		height: 100px;
		width: 250px;
		padding: 1rem;
		filter: grayscale(100%);
	}
	.slide:hover {
		filter: none;
	}
}
@media (max-width: 699px) {
	.slide {
		height: 100px;
		width: 250px;
		padding: 1rem;
		filter: none !important;
	}
}