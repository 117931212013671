@import url('https://fonts.googleapis.com/css2?family=Exo:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');

@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');
}
:root {
  --primary-font: Poppins, sans-serif;
  --primary-color: #e32415; 
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  background-size: cover;
}

/* Header */

.inicio {
  height: 80% !important;
  background-color: #f1f1f1;
  margin-top: -7rem !important;
  margin-bottom: -3rem !important;
  padding: 1rem;
}


.parrafoHome {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: -1rem;
  margin-top: -7rem;
  text-align: center;
  color: #000000;
}

.logo-header {
  filter: drop-shadow(8px 8px 20px red)
}
@media (max-width: 699px) {
  .logo-header {
    width: 60%;
  }
}
@media (max-width: 1360px) {
  .logo-header {
    width: 60%;
  }
  .cursor {
    display: flex;
    align-items: center;
    margin-top: 0.5rem !important;
    filter: invert(100%);
    animation: myAnim 8s ease 0s infinite normal forwards;
  }
  .parrafoHome {
    font-family: 'Poppins', sans-serif;
    margin-top: -1rem !important;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    color: #000000;
  }
}
@media (max-width: 320px) {
  .parrafoHome {
    font-family: 'Poppins', sans-serif;
    margin-top: -1rem !important;
    font-weight: bold;
    font-size: 1.2rem;
    font-size: 1rem;
    margin-top: -7rem !important;
    margin-bottom: -1rem !important;
    text-align: center;
    color: #000000;
  }
}
@media (max-width: 376px) {
  .parrafoHome {
    font-family: 'Poppins', sans-serif;
    margin-top: -1rem !important;
    font-weight: bold;
    font-size: 1.2rem;
    font-size: 1rem;
    margin-top: -7rem !important;
    margin-bottom: -1rem !important;
    text-align: center;
    color: #000000 !important;
  }
}
@media (max-width: 426px) {
  .parrafoHome {
    font-family: 'Poppins', sans-serif;
    margin-top: -1rem !important;
    font-weight: bold;
    font-size: 1.2rem;
    font-size: 1rem;
    margin-top: -7rem !important;
    margin-bottom: -1rem !important;
    text-align: center;
    color: #000000 !important;
  }
}
@media (max-width: 769px) {
  .parrafoHome {
    font-family: 'Poppins', sans-serif;
    margin-top: -1rem !important;
    font-weight: bold;
    font-size: 1.2rem;
    font-size: 1rem;
    margin-top: -7rem !important;
    margin-bottom: -1rem !important;
    text-align: center;
    color: #000000 !important;
  }
}
@media (max-width: 1025px) {
  .parrafoHome {
    font-family: 'Poppins', sans-serif;
    margin-top: -1rem !important;
    font-weight: bold;
    font-size: 1.2rem;
    font-size: 1rem;
    margin-top: -7rem !important;
    margin-bottom: -1rem !important;
    text-align: center;
    color: #000000 !important;
  }
}
@media (max-width: 1141px) {
  .parrafoHome {
    font-family: 'Poppins', sans-serif;
    margin-top: -1rem !important;
    font-weight: bold;
    font-size: 1.2rem;
    font-size: 1rem;
    margin-top: -7rem !important;
    margin-bottom: -1rem !important;
    text-align: center;
    color: #000000 !important;
  }
}
@media (max-width: 1361px) {
  .parrafoHome {
    font-family: 'Poppins', sans-serif;
    margin-top: -1rem !important;
    font-weight: bold;
    font-size: 1.2rem;
    font-size: 1rem;
    margin-top: -7rem !important;
    margin-bottom: -1rem !important;
    text-align: center;
    color: #000000 !important;
  }
}

.containerElements {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
  padding: 1rem;
}

/* Botón WhatsApp*/

.btn-wsp {
  position: fixed;
  width: 55px;
  height: 55px;
  line-height: 55px;
  bottom: 30px;
  right: 30px;
  z-index: 100;
}
@media (max-width: 320px) {
  .btn-wsp {
    position: fixed;
    width: 35px;
    height: 35px;
    line-height: 55px;
    bottom: 30px;
    right: 30px;
    z-index: 100;
  } 
  .btn-hover {
    width: 150px !important;
    font-size: 12px !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: rgb(255, 255, 255);
    cursor: pointer;
    margin: 5px !important;
    height: 35px !important;
    text-align:center;
    border: none;
    margin-bottom: -6rem !important;
    background-size: 300% 100%;
    border-radius: 50px;
    margin-bottom: -4rem !important;
  
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
}
@media (max-width: 376px) {
  .btn-wsp {
    position: fixed;
    width: 35px;
    height: 35px;
    line-height: 55px;
    bottom: 30px;
    right: 30px;
    z-index: 100;
  } 
  .btn-hover {
    width: 150px !important;
    font-size: 12px !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: rgb(255, 255, 255);
    cursor: pointer;
    margin: 5px !important;
    height: 35px !important;
    text-align:center;
    border: none;
    margin-bottom: -6rem !important;
    background-size: 300% 100%;
    border-radius: 50px;
    margin-bottom: -4rem !important;
  
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
}
@media (max-width: 426px) {
  .btn-wsp {
    position: fixed;
    width: 45px;
    height: 45px;
    line-height: 55px;
    bottom: 30px;
    right: 30px;
    z-index: 100;
  } 
  .btn-hover {
    width: 150px !important;
    font-size: 12px !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: rgb(255, 255, 255);
    cursor: pointer;
    margin: 5px !important;
    height: 35px !important;
    text-align:center;
    border: none;
    margin-bottom: -6rem !important;
    background-size: 300% 100%;
    border-radius: 50px;
    margin-bottom: -4rem !important;
  
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
}
@media (max-width: 769px) {
  .btn-hover {
    width: 180px !important;
    font-size: 14px !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: rgb(255, 255, 255);
    cursor: pointer;
    margin: 10px !important;
    height: 45px !important;
    text-align:center;
    border: none;
    margin-bottom: -6rem !important;
    background-size: 300% 100%;
    border-radius: 50px;
    margin-bottom: -4rem !important;
  
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
}

/* Botón inicio */

.btn-hover {
  width: 200px;
  font-size: 10px !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: rgb(255, 255, 255);
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align:center;
  border: none;
  margin-bottom: -6rem !important;
  background-size: 300% 100%;
  border-radius: 50px;
  margin-bottom: -4rem !important;
  margin-right: -3rem !important;

  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}
.btn-hover.color-2 {
  background-image: linear-gradient(to right, #f5ce62, #e43603, #fa7199, #e85a19);
  box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
  width: 100px !important;
  height: 30px !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 10px 15px rgba(255, 0, 0, 0.446));
}
.cursor {
  display: flex;
  align-items: center;
  margin-top: -3rem !important;
  margin-bottom: 2rem;
  height: 40px;
  width: 40px;
  filter: invert(0%);
  animation: myAnim 8s ease 0s infinite normal forwards;
}
@keyframes myAnim {
	0%,
	100% {
		transform: translateY(0);
	}

	10%,
	30%,
	50%,
	70% {
		transform: translateY(-8px);
	}

	20%,
	40%,
	60% {
		transform: translateY(8px);
	}

	80% {
		transform: translateY(6.4px);
	}

	90% {
		transform: translateY(-6.4px);
	}
}

/* Carrusel */

.carousel, .item2, .active {
  height:100% !important;
  }
  .carruselCaption {
    color: #fff;
    text-align: center;
  }
  .textCaption {
    font-size: 2.5vw;
    text-transform: uppercase;
    font-weight: bold;
    font-family: var(--primary-font);
  }
  .imgCarrusel {
    filter: brightness(0.5) contrast(110%);
  }
  .carousel-inner {
  height:100% !important;
  }
  .carousel {
  margin-bottom: 60px !important;
  display: block !important;
  display: flex !important;
  flex-direction: row !important;
  }
  .carousel-caption {
  z-index: 10 !important;
  }
  .carousel .item2 {
  background-color: #777 !important;
  }
  .carousel .carousel-inner .bg {
  background-repeat:no-repeat !important;
  background-size:cover !important;
  }
  @media (max-width: 320px) {
    .carousel {
      width: 100% !important;
      }
      .carousel-caption {
        z-index: 10 !important;
        color: #ffffff;
        display: block !important;
      }
      .textCaption {
        z-index: 10 !important;
        font-size: .7rem;
      }
  }
  @media (max-width: 376px) {
    .carousel {
      width: 100% !important;
      }
      .carousel-caption {
        z-index: 10 !important;
        color: #ffffff;
        display: block !important;
      }
      .textCaption {
        z-index: 10 !important;
        font-size: .7rem;
      }
  }
  @media (max-width: 426px) {
    .carousel {
      width: 100% !important;
      }
      .carousel-caption {
        z-index: 10 !important;
        color: #ffffff;
        display: block !important;
      }
      .textCaption {
        z-index: 10 !important;
        font-size: .7rem;
      }
  }
  @media (max-width: 769px) {
    .carousel {
      width: 100% !important;
      }
      .carousel-caption {
        z-index: 10 !important;
        color: #ffffff;
        display: block !important;
      }
      .textCaption {
        z-index: 10 !important;
        font-size: .7rem;
      }
  }



/* Nosotros */

.containerNosotros {
  display: flex;
  flex-direction: column;
  background-image: url("./assets/White\ and\ noise.jpg");
}

.titleNosotros {
  text-align: center;
  font-size: 3rem;
  color: var(--primary-color);
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
}

.text-nosotros {
  color: rgb(0, 0, 0);
  padding: 1.3rem;
  width: 50%;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
}
.box-padre {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 4rem;
}
@media (max-width: 699px) {
  .text-nosotros {
    color: rgb(0, 0, 0);
    padding: 1rem;
    width: 100%;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;    
    margin-top: 1rem;
  }  
  .div-img {
    background: linear-gradient(315deg, #03a9f4, #ff0058);
  }
  .img-nosotros {
    mix-blend-mode: multiply;
    filter: grayscale(100%);
    padding: 1rem;
    margin-left: 1rem;
    width: 90%;
    display: flex;
    align-self: center;    
  }
  .box-nosotros {
    display: flex;
    flex-direction: column;
  }
  .titleNosotros {
    text-align: center;
    font-size: 1.8rem;
    color: var(--primary-color);
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
  }
  .box-padre {
    display: flex;
    flex-direction: column;
  }
}

iframe {
  padding: 1rem;
  margin-left: 2rem;
}

.epigrafe {
  text-align: start;
  font-style: italic;
  font-size: .8rem;
  margin-left: 2.9rem;
  margin-bottom: 0.6rem;
  text-indent: 0;
  color: rgba(167, 167, 167, 0.701);
}

span {
  color: var(--primary-color);
}

.box-nosotros {
  display: flex;
  flex-direction: row;
}
.div-img {
  background: linear-gradient(315deg, #ffbc00, #ff0058);
}
.img-nosotros {
  filter: grayscale(0%);
  padding: 1rem;
  margin-left: 1rem
}

/*  Iconos  */

.container-iconos {
  display: flex;
  flex-direction: row;
  justify-content: space-around !important;
  margin-top: -2rem !important;
}
.text-eleginos {
  color: rgb(0, 0, 0);
  padding: 2rem;
  width: 100%;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
}
.text-icons {
  font-size: 0.8rem;
  color: rgb(0, 0, 0) !important;
  font-weight: bold;
  text-align: center !important;
}
.imagenEnvios {
  width: 25%;
  height: 25%;
}
.imagenCalidad {
  width: 25%;
  height: 25%;
}
.imagenAhora12 {
  width: 25%;
  height: 25%;
}
.imagenMercadoPago {
  width: 25%;
  height: 25%;
}
.imagenConfianza {
  width: 25%;
  height: 25%;
}



/* Contacto */
.container-contact {
  padding: 1rem;
  background-image: url("./assets/White\ and\ noise.jpg");
}
.containerContact {
  background: linear-gradient(315deg, #ffbc00, #ff0058);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.titleContacto {
  text-align: center;
  font-size: 3rem;
  color: var(--primary-color);
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  margin-top: 5rem !important;
}
@media (max-width: 699px) {
  .titleContacto {
    text-align: center;
    font-size: 1.8rem;
    color: var(--primary-color);
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    margin-top: 2rem;
  }
  .container-contact {
    padding: 1rem;
    background-image: url("./assets/White\ and\ noise.jpg");
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 1360px) {
  .titleContacto {
    text-align: center;
    font-size: 2rem;
    color: var(--primary-color);
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    margin-top: 4rem !important;
  }
  .container-contact {
    padding: 1rem;
    background-image: url("./assets/White\ and\ noise.jpg");
    display: flex;
    flex-direction: column;
  }
}
.titleContacto2 {
  text-align: center;
  font-size: 2rem;
  color: white;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  margin-top: 4rem;  
  padding: 1.5rem;
}
.container-iconos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2rem !important;
}

@media (max-width: 699px) {
  .container-iconos {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 3rem;
  }
}
.texto-primeroT {
  color: #ffffff;
  font-weight: bold;
  font-size: 3rem;
  text-align: center;
}
.text-icons {
  font-size: 1.3rem;
  color: #f9f9f9;
  font-weight: bold;
  text-align: center;
}

.text-icons:hover {
  color: black;
  filter: drop-shadow(3px 5px 20px rgb(255, 255, 255))
}
.imagenD {
  width: 25%;
  height: 55%;
}
.imagenC {
  width: 15%;
  height: 15%;
  filter: drop-shadow(3px 5px 20px rgb(255, 255, 255))
}
.imagenE {
  width: 15%;
  height: 15%;
  filter: drop-shadow(3px 5px 20px rgb(255, 255, 255))
}
a {
  text-decoration: none;
  color: #e32415;
}

.text-contacto {
  color: rgb(255, 255, 255);
  padding: 1rem;
  margin-top: 1rem;
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
}

.mapsContacto {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 5rem;
}

.formularioContainer {
  background-image: url("./assets/cilindros.jpg");
  background-size: cover;
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.formularioContainer2 {
  background-image: url("./assets/cilindros.jpg");
  background-size: cover;
  margin-top: 6rem;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form {
  display: flex;
  flex-direction: column;
  width: 50vw;
  margin-bottom: 3rem;
  padding: 2rem;
  font-family: var(--secondary-font);
  color: white;
}

/* Footer */



footer {
  background-color: #000000;
  padding: 0.5rem;
}

.containerFooter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.prefooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.text-footer {
  text-align: center;
  color: rgb(156, 156, 156);
  font-size: .8rem;
}
.text-footer2 {
  text-align: center;
  color: rgb(110, 110, 110);
  opacity: 100%;
  font-size: .7rem;
}
.firma {
  text-transform: none;
  color: #00fe7f95;
  font-size: .7rem;
}
.firma:hover {
  color: #00fe7f;
}
#get-current-year {
  color: #e32415;
}


/* Enviado */

.titleEnviado {
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  color: black;
  padding: 1rem;
  margin-top: 7rem;
  font-size: 2.5rem;
  margin-left: 2rem;
  text-align: center;
  height: 50vh;
}
.btnEnviado {
    padding: 12px 10px;
    text-align: center;
    border: 0;
    background: linear-gradient(315deg, #ffbc00, #ff0058);
    border-radius: 3px;
    margin-top: 10px;
    color: #fff;
    letter-spacing: 1px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    cursor: pointer;
}
.contenedorBtn {
  display: flex;
  justify-content: center;
  padding: 1rem;
}
@media (max-width: 699px) {
  .titleEnviado {
    font-size: 1.5rem;
  }  
}

